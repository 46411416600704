/// <reference path="./../../../../typings/tsd.d.ts"/>

export class DetailsController {
	static $inject = ['$location', '$scope', '$log'];

	public vm;

	constructor(private $location, $scope, private $log) {
		this.vm = $scope;

	this.activate();
	}

	public activate() { }
}

angular
	.module('app.details', [])
	.controller('detailsController', DetailsController);